// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/home',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  backend_endpoint: `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/`,
  backend_domain: `${process.env.REACT_APP_BACKEND_DOMAIN}`
};

export default config;
export const drawerWidth = 300;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';


