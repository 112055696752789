import { Grid } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { useTheme } from '@mui/material/styles';
import {
	Link,
	useLocation
	// useNavigate
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// import Scanner from 'components/icons';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { Language } from 'language';
import CircularProgress from '@mui/material/CircularProgress';
const BottomBarButtons = (props) => {
	const theme = useTheme();
	const location = useLocation();
	const { pathname } = location;
	const countries = useSelector((state) => state.activeCountry.data);
	const user = useSelector((state) => state.user.data);
	const [country, setCountry] = useState([]);

	useEffect(() => {
		if (countries) {
			setCountry(countries?.find((country) => country.short_code === user.country_short_code));
		}
	}, [user, countries]);

	// const naviate = useNavigate();
	const isPathSelected = (path) => {
		if (pathname.includes(path)) return theme.palette.navButton.closed;
	};
	return (
		<Grid
			container
			sx={{
				position: 'fixed',
				// width: props.open ? 'calc(100% - 260px)' : '100%',
				maxWidth: '100%',
				background: theme.palette.background.paper,
				borderTop: `1px solid ${theme.palette.divider}`,
				right: 0,
				zIndex: 400,
				bottom: 0
			}}
			padding={0.5}
		>
			<Grid item xs={2.4} display="flex" justifyContent="center" alignItems="center">
				<Grid
					container
					display="flex"
					justifyContent="center"
					alignItems="center"
					component={Link}
					to="/home"
					sx={{ color: theme.palette.text.primary, textDecoration: 'none', background: isPathSelected('/home') }}
				>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
						<FmdGoodOutlinedIcon sx={{ height: '50px' }}></FmdGoodOutlinedIcon>
					</Grid>
					<Grid item xs={12} sx={{ fontSize: '12px' }} display="flex" justifyContent="center" alignItems="center">
						{Language.heading.map}
					</Grid>
				</Grid>
			</Grid>
			{country?.is_shop_enabled == undefined ? (
				<Grid item xs={2.4} display="flex" justifyContent="center" alignItems="center">
					<CircularProgress size="30px" />
				</Grid>
			) : (
				<Grid item xs={2.4} display="flex" justifyContent="center" alignItems="center">
					<Grid
						container
						display="flex"
						justifyContent="center"
						alignItems="center"
						component={Link}

						to={country?.is_shop_enabled ? '/shop' : '/charging-history'}
						sx={{
							color: theme.palette.text.primary,
							textDecoration: 'none',
							background: isPathSelected(country?.is_shop_enabled ? '/shop' : '/charging-history')
						}}
					>
						<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
							{country?.is_shop_enabled ? (
								<LocalMallOutlinedIcon sx={{ height: '50px' }} />
							) : (
								<ElectricBoltIcon sx={{ height: '50px' }} />
							)}
						</Grid>
						<Grid item xs={12} sx={{ fontSize: '12px' }} display="flex" justifyContent="center" alignItems="center">
							{country?.is_shop_enabled ? Language.heading.shop : Language.heading.history}
						</Grid>
					</Grid>
				</Grid>
			)}

			<Grid item xs={2.4} display="flex" justifyContent="center" alignItems="center">
				<Grid
					container
					display="flex"
					justifyContent="center"
					alignItems="center"
					component={Link}
					onClick={props.qr}
					to="/qr-scanner"
					sx={{
						color: theme.palette.text.primary,
						textDecoration: 'none',
						background: isPathSelected('/qr-scanner'),
						cursor: 'pointer'
					}}
				>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
						<Grid container>
							<Grid
								item
								xs={12}
								display="flex"
								justifyContent="center"
								alignItems="center"
								sx={{
									backgroundColor: 'black',
									color: 'white',
									position: 'absolute',
									height: '70px',
									width: '70px',
									borderRadius: '100%',
									top: -20,
									border: '2px solid rgb(255,255,255)',
									boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.7)'
								}}
							>
								<QrCodeScannerIcon sx={{ color: 'white' }}></QrCodeScannerIcon>
							</Grid>
						</Grid>
						{/* <div style={{ position: 'relative', top: -20, background: 'black', borderRadius: '50%', width: '100%' }}>
								<CropFreeIcon sx={{ height: '50px', color: theme.palette.common.white }}></CropFreeIcon>
							</div> */}
						{/* <img src={scanner} alt="scanner" style={{ position: 'absolute', top: -55, height: 120}}></img> */}
					</Grid>
					{/* <Grid item xs={12} sx={{ fontSize: '12px' }} display="flex" justifyContent="center" alignItems="center">
							Scanner
						</Grid> */}
				</Grid>
			</Grid>
			<Grid item xs={2.4}>
				<Grid
					container
					display="flex"
					justifyContent="center"
					alignItems="center"
					component={Link}
					to="/wallet"
					sx={{ color: theme.palette.text.primary, textDecoration: 'none', background: isPathSelected('/wallet') }}
				>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
						<AccountBalanceWalletOutlinedIcon sx={{ height: '50px' }}></AccountBalanceWalletOutlinedIcon>
					</Grid>
					<Grid item xs={12} sx={{ fontSize: '12px' }} display="flex" justifyContent="center" alignItems="center">
						{Language.heading.wallet}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={2.4} display="flex" justifyContent="center" alignItems="center">
				<Grid
					container
					display="flex"
					justifyContent="center"
					alignItems="center"
					component={Link}
					to="/my-account"
					sx={{ color: theme.palette.text.primary, textDecoration: 'none', background: isPathSelected('/my-account') }}
				>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
						<PermIdentityOutlinedIcon sx={{ height: '50px' }}></PermIdentityOutlinedIcon>
					</Grid>
					<Grid item xs={12} sx={{ fontSize: '12px' }} display="flex" justifyContent="center" alignItems="center">
						{Language.heading.profile}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default BottomBarButtons;
