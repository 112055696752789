// material-ui
// import { Box, useMediaQuery } from '@mui/material';

// project import
import Search from './Search';
// import Profile from './Profile';
import Notification from './Notification';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
// import MobileSection from './MobileSection';
// import Logout from './Logout';
// import { getCookie } from 'common';
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
	const location = useLocation();
	const { pathname } = location;

	const slugToName = (pathname) => {
		const matches = pathname.match(/\/([^/]+)\//) || pathname.match(/\/([^/]+)$/);

		if (matches && matches[1]) {
			// Replace '-' with ' ' and then capitalize each word.
			return matches[1]
				.replace(/-/g, ' ')
				.split(' ')
				.map((word) => {
					return word.charAt(0).toUpperCase() + word.slice(1);
				})
				.join(' ');
		}
		return null;
	};

	return (
		<>
			{pathname == '/home' ? (
				<Search />
			) : pathname.startsWith('/shop/product/buy') ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Order Summary
					</Grid>
				</Grid>
			) : pathname == '/shop/change-address/' ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Change Address
					</Grid>
				</Grid>
			) : pathname == '/shop/add-address/' ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Add Address
					</Grid>
				</Grid>
			) : pathname == '/shop/order/history' ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Order History
					</Grid>
				</Grid>
			) : pathname.startsWith('/shop/order/') ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Order details
					</Grid>
				</Grid>
			) : pathname.startsWith('/billing/change-address/') || pathname.startsWith('/contact/change-address/') ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Change Address
					</Grid>
				</Grid>
			) : pathname.startsWith('/billing/add-address/') || pathname.startsWith('/contact/add-address/') ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Add Address
					</Grid>
				</Grid>
			) : pathname.startsWith('/contact/change-address/') || pathname.startsWith('/contact/change-address/') ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Change Address
					</Grid>
				</Grid>
			) : pathname.startsWith('/contact/add-address/') || pathname.startsWith('/contact/add-address/') ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Add Address
					</Grid>
				</Grid>
			) : pathname.startsWith('/shop') ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						Terra Shop
					</Grid>
				</Grid>
			) : pathname.startsWith('/rfid') ? (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						RFID
					</Grid>
				</Grid>
			) : (
				<Grid container>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ fontWeight: 'bolder' }}>
						{slugToName(pathname)}
					</Grid>
				</Grid>
			)}

			<Notification />
		</>
	);
};

export default HeaderContent;
