import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Authenticator from 'components/middleware/authenticator';
import AddAddress from 'pages/charger/AddAddress';
import UserConsent from 'pages/privacy-policy/UserConsent';

const Home = Loadable(lazy(() => import('pages/home/Home')));
const HomeTC = Loadable(lazy(() => import('pages/home/HomeTC')));
const MyAccount = Loadable(lazy(() => import('pages/my-account/MyAccount')));
const ChargingHistory = Loadable(lazy(() => import('pages/charging-history/ChargingHistory')));
const MyVehicles = Loadable(lazy(() => import('pages/my-vehicles/MyVehicles')));
const MyChargingCards = Loadable(lazy(() => import('pages/my-charging-cards/MyChargingCards')));
const MyUsage = Loadable(lazy(() => import('pages/my-usage/MyUsage')));
const WalletWrapper = Loadable(lazy(() => import('pages/wallet/WalletWrapper')));
const MyBookings = Loadable(lazy(() => import('pages/my-bookings/MyBookings')));
const HelpSupport = Loadable(lazy(() => import('pages/help-support/HelpSupport')));
const QRcodeScanner = Loadable(lazy(() => import('pages/qr-scanner/QRScanner')));
const StationList = Loadable(lazy(() => import('pages/list-stations/StationList')));
const ChargerDetails = Loadable(lazy(() => import('pages/charger/ChargerDetails')));
const OngoingChargingStatus = Loadable(lazy(() => import('pages/charger/OngoingChargingStatus')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/privacy-policy/PrivacyPolicy')));
const TermsOfService = Loadable(lazy(() => import('pages/terms-of-service/TermsOfService')));
const OTPVerify = Loadable(lazy(() => import('pages/authentication/OTPVerify')));
const ContactUs = Loadable(lazy(() => import('pages/contact-us/ContactUs')));
const PaymentCharges = Loadable(lazy(() => import('pages/payment-charges/PaymentCharges')));
const CancellationRefunds = Loadable(lazy(() => import('pages/Cancellation-refund/CancellationRefunds')));
const AboutUs = Loadable(lazy(() => import('pages/about-us/AboutUs')));
const Facility = Loadable(lazy(() => import('pages/facility/Facility')));
const NotFound404 = Loadable(lazy(() => import('pages/404/NotFound')));
const ApplyCoupon = Loadable(lazy(() => import('pages/apply-coupon/ApplyCoupon')));
const PaymentSuccessfull = Loadable(lazy(() => import('pages/payment-successful/PaymentSuccessfull')));
const PaymentNotSuccessfull = Loadable(lazy(() => import('pages/payment-not-successful/PaymentNotSuccessfull')));
const RFID = Loadable(lazy(() => import('pages/rfid/RFID')));
const OrderConfirmed = Loadable(lazy(() => import('pages/rfid/OrderConfirmed')));
const EmailVerificationSuccess = Loadable(lazy(() => import('components/EmailVerificationSuccess')));
const EmailVerificationFailure = Loadable(lazy(() => import('components/EmailVerificationFailure')));
const FavouriteChargers = Loadable(lazy(() => import('pages/favourite-chargers/FavouriteChargers')));
const Faq = Loadable(lazy(() => import('pages/customer-support/Faq')));
const Notification = Loadable(lazy(() => import('pages/notification/Notification')));
const RequestChargerMap = Loadable(lazy(() => import('pages/request-charger/RequestChargerMap')));
const PaymentStatus = Loadable(lazy(() => import('pages/payment-status/PaymentStatus')));
const Shop = Loadable(lazy(() => import('pages/shop/Shop')));
const Product = Loadable(lazy(() => import('pages/shop/Product')));
const OrderHistory = Loadable(lazy(() => import('pages/shop/OrderHistory')));
const OrderSummary = Loadable(lazy(() => import('pages/shop/OrderSummary')));
const ShopAddress = Loadable(lazy(() => import('pages/shop/AddAddress')));
const EditAddress = Loadable(lazy(() => import('pages/shop/EditAddress')));
const ChangeAddress = Loadable(lazy(() => import('pages/shop/ChangeAddress')));
const FacilityMap = Loadable(lazy(() => import('pages/shop/FacilityMap')));
const FacilityDetails = Loadable(lazy(() => import('pages/shop/FacilityDetails')));
const OrderDetails = Loadable(lazy(() => import('pages/shop/OrderDetails')));


// ==============================|| MAIN ROUTING ||============================== //

const MyAccountRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'home',
      element: <Authenticator component={<Home />} />
    },
    {
      path: 'my-account',
      element: <Authenticator component={<MyAccount />} />
    },
    {
      path: 'charging-history',
      element: <Authenticator component={<ChargingHistory />} />
    },
    {
      path: 'my-vehicles',
      element: <Authenticator component={<MyVehicles />} />
    },
    {
      path: 'rfid',
      element: <Authenticator component={<RFID />} />
    },
    {
      path: 'order-confirmed',
      element: <Authenticator component={<OrderConfirmed />} />
    },
    {
      path: 'my-charging-cards',
      element: <Authenticator component={<MyChargingCards />} />
    },
    {
      path: 'my-usage',
      element: <Authenticator component={<MyUsage />} />
    },
    {
      path: 'wallet',
      element: <WalletWrapper />
    },
    {
      path: 'my-bookings',
      element: <Authenticator component={<MyBookings />} />
    },
    {
      path: 'help-support',
      element: <Authenticator component={<HelpSupport />} />
    },
    {
      path: 'qr-scanner',
      element: <Authenticator component={<QRcodeScanner />} />
    },
    {
      path: 'list-stations',
      element: <Authenticator component={<StationList />} />
    },
    {
      path: 'charger/:id',
      element: <Authenticator component={<ChargerDetails />} />
    },
    {
      path: 'charger-status/:id',
      element: <Authenticator component={<OngoingChargingStatus />} />
    },
    {
      path: '/add-address/:address_type',
      element: <Authenticator component={<AddAddress />} />
    },
    {
      path: 'pdpa',
      element: <Authenticator component={<UserConsent />} />
    },
    {
      path: 'privacy-policy',
      element: <Authenticator component={<PrivacyPolicy />} />
    },
    {
      path: 'terms-of-service',
      element: <Authenticator component={<TermsOfService />} />
    },
    {
      path: 'contact-us',
      element: <Authenticator component={<ContactUs />} />
    },
    {
      path: 'payment-charges',
      element: <Authenticator component={<PaymentCharges />} />
    },
    {
      path: 'cancellation-refund',
      element: <CancellationRefunds />
    },
    {
      path: 'about-us',
      element: <Authenticator component={<AboutUs />} />
    },
    {
      path: 'otp-verify',
      element: <Authenticator component={<OTPVerify />} />
    },
    {
      path: '*',
      element: <NotFound404 />
    },
    {
      path: 'facility/:id',
      element: <Authenticator component={<Facility />} />
    },
    {
      path: 'apply-coupon/:charger_id',
      element: <Authenticator component={<ApplyCoupon />} />
    },
    {
      path: 'request-charger/',
      element: <Authenticator component={<RequestChargerMap />} />
    },
    {
      path: 'hometc',
      element: <HomeTC />
    },
    {
      path: 'email/verify/success',
      element: <EmailVerificationSuccess />
    },
    {
      path: 'email/verify/failure',
      element: <EmailVerificationFailure />
    },
    {
      path: 'favourite-chargers',
      element: <Authenticator component={<FavouriteChargers />} />
    },
    {
      path: 'faq',
      element: <Authenticator component={<Faq />} />
    },
    {
      path: 'notifications',
      element: <Authenticator component={<Notification />} />
    },
    {
      path: 'payment/success',
      element: <PaymentSuccessfull />
    },
    {
      path: 'payment/failed',
      element: <PaymentNotSuccessfull />
    },
    {
      path: 'payment/status',
      element: <PaymentStatus />
    },
    {
      path: 'shop/',
      element: <Authenticator component={<Shop />} />
    },
    {
      path: 'shop/product/:id',
      element: <Authenticator component={<Product />} />
    },
    {
      path: 'shop/order/history',
      element: <Authenticator component={<OrderHistory />} />
    },
    {
      path: 'shop/product/buy/:id',
      element: <Authenticator component={<OrderSummary />} />
    },
    {
      path: ':address_type/add-address/',
      element: <Authenticator component={<ShopAddress />} />
    },
    {
      path: ':address_type/edit-address/',
      element: <Authenticator component={<EditAddress />} />
    },
    {
      path: ':address_type/change-address/',
      element:<Authenticator component={<ChangeAddress />} />
    },
    {
      path: 'shop/facility-location',
      element: <Authenticator component={<FacilityMap />} />
    },
    {
      path: 'shop/onboard/add-facility',
      element: <Authenticator component={<FacilityDetails />} />
    },
    {
      path: 'shop/order/:id',
      element: <Authenticator component={<OrderDetails />} />
    }
  ]
};

export default MyAccountRoutes;
